import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "About PT Blink",
      description: {
        internal: {
          content:
            "Our purpose is to transform property development and operation through technology. Our vision is to apply our purpose at scale and amplify the benefits of the Blink DMI® method for the world.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextBanner",
        heading: "About PT Blink",
        subHeading: "Murray Ellen, Founder, PT Blink",
        buttonText: null,
        buttonLink: null,
        content: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The construction industry is continually trying to address risks relating to delivery timeframes, cost versus budget, material wastage and levels of worker injury. ","marks":[],"data":{}},{"nodeType":"text","value":"Now there is a better way!","marks":[{"type":"bold"}],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
        },
        id: "e33c6756-a0ad-59f4-9bc7-fa7b474b1563",
      },
      {
        __typename: "ContentfulImageSection",
        caption:
          "Sydney Olympic Stadium reconfiguration using post-tensioned steel technology by Murray Ellen",
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=750&h=375&q=100&fm=webp&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1080&h=540&q=100&fm=webp&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1366&h=683&q=100&fm=webp&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1920&h=960&q=100&fm=webp&bg=white 1920w",
                  sizes: "100vw",
                  type: "image/webp",
                },
              ],
              fallback: {
                src: "https://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=750&h=375&q=100&fm=png&bg=white",
                srcSet:
                  "https://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=750&h=375&q=100&fm=png&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1080&h=540&q=100&fm=png&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1366&h=683&q=100&fm=png&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/2KEwpokczjWV87JOvbKsQ9/e3e66de9219dcf8ff3e18cb588f58578/sydney-olympic-stadium-aerial.png?w=1920&h=960&q=100&fm=png&bg=white 1920w",
                sizes: "100vw",
              },
            },
            layout: "fullWidth",
            backgroundColor: "white",
            width: 1,
            height: 0.5,
          },
        },
        id: "92b222b7-ee1b-59cb-9637-fdf2c3b10c33",
      },
      {
        __typename: "ContentfulTextColumnsSection",
        displayType: "white",
        heading: "Our mission, vision and mantra",
        longSubHeading: {
          internal: {
            content:
              "Our mission is to transform property development and operation through technology. Our vision is to achieve this at scale and amplify the benefits of the Blink DMI® method for the world. Our simple mantra keeps us focused – faster, safer, better with less waste!​",
          },
        },
        column1: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"PT Blink is the result of more than 35 years of project work around the world by Founder and Executive Director Murray Ellen, a renowned structural engineer and a governor of Warren Centre for Advanced Engineering at Sydney University. ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Murray developed scalable building technology using a patented design algorithm and a unique post-tensioned steel backbone that enables long-span structures such as aircraft hangers and stadiums. The post-tensioned steel technology is the origin of the ‘PT’ in our name, while the ‘Blink’ refers to the speed of build that it enables.​","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"​Murray recognised the potential for his technology to transform multistorey construction. His goal was to achieve faster, safer and better building outcomes with less waste.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
        },
        column2: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"This could be achieved by enabling the building structure and non-structural components to be manufactured offsite with complete geometric accuracy and then integrated onsite to create the finished building. Critical was the ability to deliver the creative intent of the designer and enable any architecture – a challenge for existing volumetric modular construction solutions. Also, fundamental was the ability to deliver an open ecosystem of participants to enable the benefits to scale without limitation.​","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Now a reality, the Blink DMI® method aims to reduce project time by at least 40% and, when used to its full potential, can almost double the internal rate of return for owners and developers. Most significant, are the potential long-tail impacts across the three key pillars of sustainability – social, environmental and economic impacts.​","marks":[],"data":{}}]}]}',
        },
        id: "abebf1f3-3f62-5309-967d-fec6606de533",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Our impact",
        displayType: "white",
        numberOfColumns: 3,
        headingLogo: null,
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "1b4a32b1-c84d-549d-81d3-4c583982fb83",
            title: {
              internal: {
                content: "Social impacts",
              },
            },
            image: {
              title: "Social impacts",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/334t3sJg3DVHIHeTNnlw1T/56516d7a3b68a5ce35c29158f90d7a36/Social-Impacts.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"A much safer, more diverse and collaborative industry driven by knowledge and craft workers.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Improved mental health and wellbeing due to the reduction of risk, uncertainty and unfair behaviour driven by financially inequitable practices of traditional construction methods.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Significantly reduced ‘at height’ physical risk to construction personnel.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"}],"nodeType":"unordered-list"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: null,
            ctaText: null,
          },
          {
            id: "8750c73d-2583-5ae9-97ee-893d6c2f455f",
            title: {
              internal: {
                content: "Environmental impacts",
              },
            },
            image: {
              title: "Environmental Impacts",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/5lxLrzaqxdlGJIb8frutcD/9ee353df47fd8f0832d50472accca23f/Environmental-Impacts.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Building and demolition waste currently accounts for more than 40% of landfill. Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" virtually eliminates waste and ensures a consistently high-quality product with customised design that integrates naturally with its environment and enables smart operations.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" enables design and manufacture down to a component levels based on a range of attributes including embodied carbon.","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
            },
            ctaLink: null,
            ctaText: null,
          },
          {
            id: "dff2074a-9acf-51b8-b0d1-6a251a80ab31",
            title: {
              internal: {
                content: "Economic impacts",
              },
            },
            image: {
              title: "Economic impacts",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/1wB9nKrfkEs5Ij4Khrf4In/93fb3a4697c434a4d5bacd716683c713/Economic-Impacts.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" ecosystem and manufacturer marketplace can help reignite sovereign manufacturing and create new jobs with geographic distribution beyond capital cities.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Higher productivity means social housing and infrastructure projects can be delivered faster and with greater long-term value.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"A higher return on investment and more efficient use of taxpayer funds for government projects.","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
            },
            ctaLink: null,
            ctaText: null,
          },
        ],
        id: "b2f537ed-52b1-5f59-a45e-e41e453819cc",
      },
      {
        __typename: "ContentfulTextColumnsSection",
        displayType: "white",
        heading: "Answering the property problem​",
        longSubHeading: {
          internal: {
            content:
              "Property projects are often delivered late and over budget, with massive waste of time, money and materials. The construction industry is continually trying to manage these risks but change has been incremental. At the heart of these risks are decades old methodologies and fundamentally outdated technology platforms. ",
          },
        },
        column1: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"In fact, traditional construction methods deliver roughly the same low productivity levels as 80 years ago (","nodeType":"text"},{"data":{"uri":"https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/Operations/Our%20Insights/Reinventing%20construction%20through%20a%20productivity%20revolution/MGI-Reinventing-construction-A-route-to-higher-productivity-Full-report.pdf"},"content":[{"data":{},"marks":[],"value":"McKinsey 2017","nodeType":"text"}],"nodeType":"hyperlink"},{"data":{},"marks":[],"value":") and cannot meet current or future demand. There is a better way.​","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"PT Blink is creating a unique technology platform and digital marketplace that enables the offsite manufacture of buildings rather than constructing them traditionally. PT Blink can minimise property project risks for owners and developers by bringing visibility, control, assurance and modern manufacturing precision to any property asset.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
        },
        column2: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"By licensing our technology platform and Blink Design - Manufacture - Integrate (Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":") method of project delivery, owners and developers will minimise the risk of time and cost overruns characteristic of traditional construction methods.​","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"​The proven Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" method also drastically improves worker safety, an issue that lies at the heart of construction risk today. Inaccuracy and waste are also significantly reduced through PT Blink’s unique parametric modelling, which makes millimetre-precise component manufacturing and mass customisation possible for any architectural design.​","marks":[],"data":{}}]}]}',
        },
        id: "73964578-fedc-5365-8f11-8269e2cb2407",
      },
      {
        __typename: "ContentfulTextPointsSection",
        heading: "Our values",
        subHeading:
          "These are the principles we live by, create by and hire by.",
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/3DMuHUJGH2O1T69elASMGA/891bb7653480ff8bf8c6424764817ded/values.jpg?w=580&h=688&q=100&fm=webp&bg=white 580w",
                  sizes: "100vw",
                  type: "image/webp",
                },
              ],
              fallback: {
                src: "https://images.ctfassets.net/thdo0maiis3p/3DMuHUJGH2O1T69elASMGA/891bb7653480ff8bf8c6424764817ded/values.jpg?w=580&h=688&fl=progressive&q=100&fm=jpg&bg=white",
                srcSet:
                  "https://images.ctfassets.net/thdo0maiis3p/3DMuHUJGH2O1T69elASMGA/891bb7653480ff8bf8c6424764817ded/values.jpg?w=580&h=688&fl=progressive&q=100&fm=jpg&bg=white 580w",
                sizes: "100vw",
              },
            },
            layout: "fullWidth",
            backgroundColor: "white",
            width: 1,
            height: 1.186206896551724,
          },
        },
        textPoints: [
          {
            id: "d450116f-801c-58b8-9db6-1122c2599d38",
            heading: "Sustainability",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"We are driven by a responsibility to strive to eliminate risk and contribute to a sustainable\\nproperty industry, now and into the future.","marks":[],"data":{}}],"data":{}}],"data":{}},{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"The three sustainability pillars of social, environmental and economic impacts are the foundation of our business, our technology and our operating plans.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"paragraph","content":[{"nodeType":"text","value":"","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/hLOZBXLE4PDbvJxWQ18TN/24c4ee5e715bfbfabee47717beaef4bd/Sustainability.svg",
              },
            },
          },
          {
            id: "fd0a7dc7-3539-504e-bb88-42abbf751df0",
            heading: "Customer centric",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"We are focussed on service excellence for our ecosystem members.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Anticipating their challenges and providing solutions guide our priorities and actions every day.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"paragraph","content":[{"nodeType":"text","value":"","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/4RUlooav0Nr1dpIlcJKaia/a7cf6ab604cd53b095ad25221abeb64e/Customer_Centric.svg",
              },
            },
          },
          {
            id: "f7f7bc17-043e-5659-ba1a-899874ae9c1f",
            heading: "Everyone matters",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"We are inclusive, listen to and respect the contribution of others and create great career opportunities for all.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"We are all encouraged to challenge status quo and are empowered and inspired to find new answers.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"paragraph","content":[{"nodeType":"text","value":"","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/5QLZsQCmsArSv4QQL2H4a8/4df5dc1646cddb4da26d7fbe5ced24e8/Everyone_Matters.svg",
              },
            },
          },
          {
            id: "ed911544-fe0f-54d2-948e-dd4a12c31dad",
            heading: "Creativity",
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"We are creative thinkers from diverse backgrounds with deep expertise and a passion to make the world better.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"We look at challenges differently and draw on our foundational principles to create innovative new value for our customers and the communities in which we work.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"}],"nodeType":"unordered-list"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/5OjwwsIz3Sr9YzoVtrmnzO/25ab9fd5b4113f9ddb4e37678a21ec2f/Creative_Freedom.svg",
              },
            },
          },
          {
            id: "2ae1bdc9-73f7-5115-a291-f91f7de1f1d9",
            heading: "Integrity always",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"We are always honest and trustworthy with strong moral principles and ethics.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"unordered-list","content":[{"nodeType":"list-item","content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Our reputation rests on our integrity and our reputation is our license to operate.","marks":[],"data":{}}],"data":{}}],"data":{}}],"data":{}},{"nodeType":"paragraph","content":[{"nodeType":"text","value":"","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/6AYZrqCcYdc9OQ6igptCjg/fc172700e86a1f961be86a09f2c8e985/Integrity_Always_4x.png",
              },
            },
          },
        ],
        id: "40eda73f-d5c7-50e9-89cb-157fded2ce9c",
      },
      {
        __typename: "ContentfulTimeline",
        heading: "Our history",
        entries: [
          {
            id: "335c24e8-0442-5d98-94e1-31dceb9be05d",
            heading: "1990's - 2015",
            summary:
              "Founder creates 25 long-span structures in Australia and overseas",
            items: [
              "Westpac Call Centre Queensland",
              "Boeing Hangar, Amberley Airforce Base",
              "Il Shun Factory, Beijing",
              "ANZ Stadium Sydney",
            ],
            showAsOpen: false,
          },
          {
            id: "cbeab321-df54-55a7-b3e1-36293796ade6",
            heading: "2016 - 2020",
            summary:
              "PT Blink is formed. Designs and technology licenses are provided to owners and developers on a project basis.",
            items: [
              "Patents registered for Blink Backbone®",
              "7 storey apartment building, Queensland",
              "Multiplex, Brisbane",
              "Ferrari Showroom, Brisbane",
              "Patents registered for Blink DMI® Cube parametric design technology",
              "Caboolture Aged Care & Commercial developments completed",
            ],
            showAsOpen: false,
          },
          {
            id: "f09e7093-c62f-58b8-82ff-bc3b8fb8a98f",
            heading: "2021 -",
            summary:
              "Creates the Blink DMI™ platform and marketplace and expands operations",
            items: [
              "Gasworks carpark & office project commences, Tasmania",
              "Western Sydney University innovation partnership",
              "Holland Partner Group capital investment & licensing agreement USA",
              "Flag Lot Apartments USA design study commences",
              "First release of Blink DMI™ technology platform (In progress)",
              "USA presence (In progress)",
              "NZ presence (In progress)",
            ],
            showAsOpen: true,
          },
        ],
        id: "b365684d-c040-5836-a543-8584f134134a",
      },
      {
        __typename: "ContentfulPeopleGallery",
        heading: "Our People",
        summary: {
          internal: {
            content:
              "With a passion to make a difference and deep experience in technology, engineering and construction businesses, our people lead our business with steady hands.",
          },
        },
        images: [
          {
            id: "6ac58a11-bda5-5d8c-8ba4-7e77d0d1d63b",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/3FWuOuD3Q6NXSfp69flJoD/abd9bf73dceea57948bd603c757e006f/team1.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "cfe622a6-16d0-5c26-8db3-9b82c7b35352",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/2xBzNFGdpk6VCZTZGYdXt3/d7f28c6c8b5c3714d039e884e02989d3/team2.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "35b6b9d4-0b50-50a3-8e1b-5f96ce4b345b",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/346CAtLS74JZqlC76gD0NK/5fe270d87acb19baa3bb5d543157fb86/team3.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "95004049-517b-5fc8-a7a6-14a76d97aa45",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/1oK8xSIRnNSaUyNmJstDfJ/4ae97a7fdf4a72281f5428dd4eb8e331/team4.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "a15042ba-d1f2-5ca5-9e52-4e13de1d6148",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/7rHTbl3vjazJZe7zhKbuyZ/9cfc1a95c66bb7976d59b544884036e3/team5.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "e3799a1f-46b1-5b66-ae71-7f004e1ce5dc",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6XtxQNN0l8BEVBD7z3hc8H/8e1e74dc7a382f947f0bf3776a8b32f2/team6.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "7b11d5bb-d95a-5678-8100-909ddaa615e7",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6jKjh39nZFGJjor68pCfSC/9dec3daf0c0ee5cc57959927475a3109/team7.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "886fc7dd-e3a7-5a5c-b258-9a737d171752",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/6a3HeEDY3Faf7TFdqpuar1/9d7d0e3c3dc0232127b96e3729d88c28/team8.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "c459c17e-d480-5d8f-86d7-a3430dca05f8",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/4uTctWCktVaxMtk9dVl5la/bc6fec0aab2d35457cd864d2769f296d/team9.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "fe0e9da7-1bfa-569f-90ec-ad10d21025b6",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/3U3c7ScH0Jwm8xSmKvIuuG/2c0908e5e0473be59b35af430325c225/team10.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "24cbdedf-aa6f-592b-a007-92d133234589",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/5yaMwGspKyNtMgQa3ftdWC/9f96de25cc7628b2f85e7e3c7a2b871b/team11.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
          {
            id: "d0ca53f5-ac83-56d0-aaa7-ff5ae26a428e",
            gatsbyImageData: {
              images: {
                sources: [
                  {
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=160&h=108&q=50&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=320&h=216&q=50&fm=webp 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=640&h=432&q=50&fm=webp 640w",
                    sizes: "(min-width: 640px) 640px, 100vw",
                    type: "image/webp",
                  },
                ],
                fallback: {
                  src: "https://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg",
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=160&h=108&fl=progressive&q=50&fm=jpg 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=320&h=216&fl=progressive&q=50&fm=jpg 320w,\nhttps://images.ctfassets.net/thdo0maiis3p/5FxOBM4OwW1OowAyCr3iwm/e54a63a8442a32bc59c19633d5e977ba/team12.jpg?w=640&h=432&fl=progressive&q=50&fm=jpg 640w",
                  sizes: "(min-width: 640px) 640px, 100vw",
                },
              },
              layout: "constrained",
              width: 640,
              height: 432,
            },
          },
        ],
        id: "99ee8e2a-0eae-5be5-b5de-dcb3b3df9412",
      },
      {
        __typename: "ContentfulTeamGallery",
        heading: "Our Board",
        subHeading: null,
        members: [
          {
            id: "70932554-6026-56dd-9ed6-77f5aa982a16",
            name: "Murray Ellen",
            position: "Founder, Executive Chairman",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=750&h=750&q=100&fm=webp&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1080&h=1080&q=100&fm=webp&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1366&h=1366&q=100&fm=webp&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1371&h=1371&q=100&fm=webp&bg=white 1371w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1371&h=1371&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=750&h=750&fl=progressive&q=100&fm=jpg&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1080&h=1080&fl=progressive&q=100&fm=jpg&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1366&h=1366&fl=progressive&q=100&fm=jpg&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/5glMlyTSPB6Moc3S1AR9hz/b60b7beb13bb283f1d8c445028dbd883/image_3.jpg?w=1371&h=1371&fl=progressive&q=100&fm=jpg&bg=white 1371w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "0f9f7cfd-bf9a-5af3-830f-17edeaf2ae31",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=52&h=13&q=50&fm=webp&fit=scale 52w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=103&h=25&q=50&fm=webp&fit=scale 103w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=206&h=50&q=50&fm=webp&fit=scale 206w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=412&h=100&q=50&fm=webp&fit=scale 412w",
                        sizes:
                          "(min-width: 206.01503759398497px) 206.01503759398497px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=206&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=52&h=13&q=50&fm=png&fit=scale 52w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=103&h=25&q=50&fm=png&fit=scale 103w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=206&h=50&q=50&fm=png&fit=scale 206w,\nhttps://images.ctfassets.net/thdo0maiis3p/6I69ewXT6yYYdfoF7ZJztK/badd82448e1c48061de0f87cb245aeac/anz-stadium.png?w=412&h=100&q=50&fm=png&fit=scale 412w",
                      sizes:
                        "(min-width: 206.01503759398497px) 206.01503759398497px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 206.01503759398497,
                  height: 50,
                },
              },
              {
                id: "e6697ddf-8000-595d-877a-257cb1b8cb3e",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=47&h=13&q=50&fm=webp&fit=scale 47w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=94&h=25&q=50&fm=webp&fit=scale 94w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=188&h=50&q=50&fm=webp&fit=scale 188w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=376&h=100&q=50&fm=webp&fit=scale 376w",
                        sizes:
                          "(min-width: 187.89808917197453px) 187.89808917197453px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=188&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=47&h=13&q=50&fm=png&fit=scale 47w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=94&h=25&q=50&fm=png&fit=scale 94w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=188&h=50&q=50&fm=png&fit=scale 188w,\nhttps://images.ctfassets.net/thdo0maiis3p/SmYqFDlG8jrDCKsgG0QoP/98d1dacecab0abdb8dd02cf2cc1a31dd/boeing.png?w=376&h=100&q=50&fm=png&fit=scale 376w",
                      sizes:
                        "(min-width: 187.89808917197453px) 187.89808917197453px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 187.89808917197453,
                  height: 50,
                },
              },
              {
                id: "8937efa7-1e1b-5334-8441-e81a0ffd5c1a",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=51&h=12&q=50&fm=webp&fit=scale 51w,\nhttps://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=103&h=25&q=50&fm=webp&fit=scale 103w,\nhttps://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=205&h=50&q=50&fm=webp&fit=scale 205w",
                        sizes:
                          "(min-width: 205.47945205479454px) 205.47945205479454px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=205&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=51&h=12&q=50&fm=png&fit=scale 51w,\nhttps://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=103&h=25&q=50&fm=png&fit=scale 103w,\nhttps://images.ctfassets.net/thdo0maiis3p/32v0faeBpaDEZBqGFtzcu3/2d909a7f0410486c1623e688837d19cf/westpac.png?w=205&h=50&q=50&fm=png&fit=scale 205w",
                      sizes:
                        "(min-width: 205.47945205479454px) 205.47945205479454px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 205.47945205479454,
                  height: 50,
                },
              },
              {
                id: "3ad189e1-a66a-53a9-9922-599e6b4bfdf8",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=55&h=13&q=50&fm=webp&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=109&h=25&q=50&fm=webp&fit=scale 109w,\nhttps://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=218&h=50&q=50&fm=webp&fit=scale 218w",
                        sizes:
                          "(min-width: 218.1159420289855px) 218.1159420289855px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=218&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=55&h=13&q=50&fm=png&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=109&h=25&q=50&fm=png&fit=scale 109w,\nhttps://images.ctfassets.net/thdo0maiis3p/2GCW3SOKY8rhrcwiOT79vm/40a5a62f403262f4cf42c8d775ef5358/qantas.png?w=218&h=50&q=50&fm=png&fit=scale 218w",
                      sizes:
                        "(min-width: 218.1159420289855px) 218.1159420289855px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 218.1159420289855,
                  height: 50,
                },
              },
              {
                id: "aa0d6e4b-1531-5536-9701-ce93ebacb63a",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=28&h=13&q=50&fm=webp&fit=scale 28w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=55&h=25&q=50&fm=webp&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=110&h=50&q=50&fm=webp&fit=scale 110w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=220&h=100&q=50&fm=webp&fit=scale 220w",
                        sizes:
                          "(min-width: 109.84848484848484px) 109.84848484848484px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=110&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=28&h=13&q=50&fm=png&fit=scale 28w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=55&h=25&q=50&fm=png&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=110&h=50&q=50&fm=png&fit=scale 110w,\nhttps://images.ctfassets.net/thdo0maiis3p/1vMVGKPqbeGGCi6z5I8qpu/a90862242bb25f01fb0f0330cd54af98/ilshin.png?w=220&h=100&q=50&fm=png&fit=scale 220w",
                      sizes:
                        "(min-width: 109.84848484848484px) 109.84848484848484px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 109.84848484848484,
                  height: 50,
                },
              },
              {
                id: "d5cbc5bd-472f-508b-bc19-7ab9e06269cd",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=36&h=12&q=50&fm=webp&fit=scale 36w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=72&h=25&q=50&fm=webp&fit=scale 72w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=144&h=50&q=50&fm=webp&fit=scale 144w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=288&h=100&q=50&fm=webp&fit=scale 288w",
                        sizes:
                          "(min-width: 144.23076923076923px) 144.23076923076923px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=144&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=36&h=12&q=50&fm=png&fit=scale 36w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=72&h=25&q=50&fm=png&fit=scale 72w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=144&h=50&q=50&fm=png&fit=scale 144w,\nhttps://images.ctfassets.net/thdo0maiis3p/6ry1aKKlBfIAjVhXdvB7lc/bdc197eab747d1ed794f670ed92f76c1/tata.png?w=288&h=100&q=50&fm=png&fit=scale 288w",
                      sizes:
                        "(min-width: 144.23076923076923px) 144.23076923076923px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 144.23076923076923,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Murray Ellen is a global expert in post-tensioned steel technology and has conceived, designed and implemented projects in countries including Australia, China, United Kingdom, Hong Kong, Malaysia, Philippines, Thailand, Taiwan and Dubai.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Murray’s broad experience includes full technical implementation, construction and development responsibility for projects which he has delivered with excellence around the world. His innovative steel design technology and construction methodologies for large-spanned structures have received multiple awards and are unique in the global steel spectrum.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Today Murray empowers and inspires a team to build on his more-than 35-year legacy, IP portfolio and original vision ","marks":[],"data":{}},{"nodeType":"text","value":"to deliver the built environment FASTER, SAFER, BETTER and with LESS WASTE through technology.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Murray also currently serves as a Governor of Sydney University’s ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"https://www.sydney.edu.au/engineering/industry-and-community/the-warren-centre.html"},"content":[{"nodeType":"text","value":"Warren Centre for Advanced Engineering","marks":[],"data":{}}]},{"nodeType":"text","value":" and is a Fellow of the ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"https://www.atse.org.au/about-us/"},"content":[{"nodeType":"text","value":"Australian Academy of Technological Sciences and Engineering","marks":[],"data":{}}]},{"nodeType":"text","value":".","marks":[],"data":{}}]}]}',
            },
          },
          {
            id: "cf056b26-c6f2-5937-ad23-8f3d8d1cd2c3",
            name: "Grant Ure",
            position: "CTO, Executive Director",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6HugGm8wR06S7rmgReHyFj/b7bd8991683663181ada4e3f9a97cc3a/Grant-Ure.jpg?w=500&h=500&q=100&fm=webp&bg=white 500w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/6HugGm8wR06S7rmgReHyFj/b7bd8991683663181ada4e3f9a97cc3a/Grant-Ure.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/6HugGm8wR06S7rmgReHyFj/b7bd8991683663181ada4e3f9a97cc3a/Grant-Ure.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white 500w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "eb74f0b1-35ae-5416-976b-82787a56f1a5",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=61&h=13&q=50&fm=webp&fit=scale 61w,\nhttps://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=121&h=25&q=50&fm=webp&fit=scale 121w,\nhttps://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=242&h=50&q=50&fm=webp&fit=scale 242w",
                        sizes:
                          "(min-width: 241.93548387096774px) 241.93548387096774px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=242&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=61&h=13&q=50&fm=png&fit=scale 61w,\nhttps://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=121&h=25&q=50&fm=png&fit=scale 121w,\nhttps://images.ctfassets.net/thdo0maiis3p/676Efn6OVC2luvjxAATyxQ/73acf645afc0b44e51f365b2ae6efd89/centaman.png?w=242&h=50&q=50&fm=png&fit=scale 242w",
                      sizes:
                        "(min-width: 241.93548387096774px) 241.93548387096774px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 241.93548387096774,
                  height: 50,
                },
              },
              {
                id: "32b26ffc-10eb-566b-ad10-85a404a79eaa",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=30&h=13&q=50&fm=webp&fit=scale 30w,\nhttps://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=59&h=25&q=50&fm=webp&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=118&h=50&q=50&fm=webp&fit=scale 118w",
                        sizes:
                          "(min-width: 118.08510638297874px) 118.08510638297874px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=118&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=30&h=13&q=50&fm=png&fit=scale 30w,\nhttps://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=59&h=25&q=50&fm=png&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/2o9GjzzTZGhiSZieCzLJ76/b659a5ade8a0fff24abceb96d3a5c0c6/primel.png?w=118&h=50&q=50&fm=png&fit=scale 118w",
                      sizes:
                        "(min-width: 118.08510638297874px) 118.08510638297874px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 118.08510638297874,
                  height: 50,
                },
              },
              {
                id: "43e96cc4-90e1-5d62-9f00-ccdf3f46d707",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=27&h=13&q=50&fm=webp&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=54&h=25&q=50&fm=webp&fit=scale 54w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=107&h=50&q=50&fm=webp&fit=scale 107w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=214&h=100&q=50&fm=webp&fit=scale 214w",
                        sizes:
                          "(min-width: 106.60377358490567px) 106.60377358490567px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=107&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=27&h=13&q=50&fm=png&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=54&h=25&q=50&fm=png&fit=scale 54w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=107&h=50&q=50&fm=png&fit=scale 107w,\nhttps://images.ctfassets.net/thdo0maiis3p/43CNKlkkJN8v20DjUozqz5/ea26b0d61b23d7ee946ec353bc40a09d/belengo.png?w=214&h=100&q=50&fm=png&fit=scale 214w",
                      sizes:
                        "(min-width: 106.60377358490567px) 106.60377358490567px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 106.60377358490567,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Grant is a software and systems development professional with an extensive record of delivery to organisations in Australia, North America, UK/Europe and the Middle East.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"As Chief Technology Officer, Grant is responsible for development of the PT Blink platform, a cloud-based architecture that unites all of the components in the PT Blink Design, Manufacture, Integrate (Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":") ecosystem, from stakeholders (architects, suppliers, manufacturers, fabricators, assemblers/integrators) to the technology underpinning Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" supply chain management.","marks":[],"data":{}}]}]}',
            },
          },
          {
            id: "4a63a6db-d9e9-5e9f-a5ce-7db571059927",
            name: "Brett Crowley",
            position: "Company Secretary, Non Executive Director",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2NHa8BKUA0gMMk6bD1cw2k/395c05ba1204b60f19074887dea66cbe/Brett-Crowley.jpg?w=500&h=500&q=100&fm=webp&bg=white 500w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2NHa8BKUA0gMMk6bD1cw2k/395c05ba1204b60f19074887dea66cbe/Brett-Crowley.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2NHa8BKUA0gMMk6bD1cw2k/395c05ba1204b60f19074887dea66cbe/Brett-Crowley.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white 500w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "7f701855-75f1-5c85-9e11-fe782b77fb59",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/7KwNlsmvqjsGfeavhYreec/4f10c0bc481b8c1f1050d58a3db565d6/ey.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
              {
                id: "5da3bb44-7c85-52a8-8cd2-3cd446be26ca",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=36&h=13&q=50&fm=webp&fit=scale 36w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=72&h=25&q=50&fm=webp&fit=scale 72w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=143&h=50&q=50&fm=webp&fit=scale 143w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=286&h=100&q=50&fm=webp&fit=scale 286w",
                        sizes:
                          "(min-width: 143.33333333333334px) 143.33333333333334px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=143&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=36&h=13&q=50&fm=png&fit=scale 36w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=72&h=25&q=50&fm=png&fit=scale 72w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=143&h=50&q=50&fm=png&fit=scale 143w,\nhttps://images.ctfassets.net/thdo0maiis3p/3aX5L09oNqZbKKhRfjxyFS/a040eccbb5726643ecd556296d5a0163/ncat.png?w=286&h=100&q=50&fm=png&fit=scale 286w",
                      sizes:
                        "(min-width: 143.33333333333334px) 143.33333333333334px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 143.33333333333334,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Brett Crowley is the CFO and company secretary. Brett is an experienced chairman, finance director and company secretary of ASX-listed companies.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"His previous roles include chairman of an ASX-listed company in the building services industry which grew significantly by way of acquisition. This was followed by five years as CEO of an ASX listed company in the water technology field. This role included Brett managing the construction of the company’s manufacturing plant in China and being based in Europe to establish the sales function of the company.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Brett is currently a director of two ASX listed companies and practices as a solicitor. He is a former partner of Ernst & Young in Australia and Hong Kong and a Senior Legal Member of the NSW Civil and Administrative Tribunal.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
          },
        ],
        id: "46cabb17-913c-5c02-91ba-b1445171f87d",
      },
      {
        __typename: "ContentfulTeamGallery",
        heading: "Our Team",
        subHeading: null,
        members: [
          {
            id: "b2f385bb-ef34-5ac5-b56d-fa164b60b862",
            name: "Wayne Larsen",
            position: "Chief Executive Officer",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6GQn5Wyqj2c2rkaEJx0EJs/eed03c7ec416b9c56a506c3d6767eec2/wayne-larson.jpg?w=500&h=500&q=100&fm=webp&bg=white 500w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/6GQn5Wyqj2c2rkaEJx0EJs/eed03c7ec416b9c56a506c3d6767eec2/wayne-larson.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/6GQn5Wyqj2c2rkaEJx0EJs/eed03c7ec416b9c56a506c3d6767eec2/wayne-larson.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white 500w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "d79f4868-dbb0-5eb7-9924-079cd1e95ef8",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=25&h=13&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=49&h=25&q=50&fm=webp&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=webp&fit=scale 98w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=196&h=100&q=50&fm=webp&fit=scale 196w",
                        sizes:
                          "(min-width: 98.07692307692307px) 98.07692307692307px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=25&h=13&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=49&h=25&q=50&fm=png&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=png&fit=scale 98w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=196&h=100&q=50&fm=png&fit=scale 196w",
                      sizes:
                        "(min-width: 98.07692307692307px) 98.07692307692307px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 98.07692307692307,
                  height: 50,
                },
              },
              {
                id: "d96d3cfc-c99b-51f8-9b05-f19c04ce5566",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=34&h=12&q=50&fm=webp&fit=scale 34w,\nhttps://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=68&h=25&q=50&fm=webp&fit=scale 68w,\nhttps://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=136&h=50&q=50&fm=webp&fit=scale 136w",
                        sizes:
                          "(min-width: 136.36363636363635px) 136.36363636363635px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=136&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=34&h=12&q=50&fm=png&fit=scale 34w,\nhttps://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=68&h=25&q=50&fm=png&fit=scale 68w,\nhttps://images.ctfassets.net/thdo0maiis3p/6c6tIbNY0PmdhpOeDdnLsD/721ed9398334aa6d80ac6c68d59de396/arrium.png?w=136&h=50&q=50&fm=png&fit=scale 136w",
                      sizes:
                        "(min-width: 136.36363636363635px) 136.36363636363635px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 136.36363636363635,
                  height: 50,
                },
              },
              {
                id: "ed0306ad-f60b-52e1-9cbb-ee13850d9136",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=27&h=12&q=50&fm=webp&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=55&h=25&q=50&fm=webp&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=109&h=50&q=50&fm=webp&fit=scale 109w",
                        sizes:
                          "(min-width: 108.53658536585367px) 108.53658536585367px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=109&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=27&h=12&q=50&fm=png&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=55&h=25&q=50&fm=png&fit=scale 55w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GcVmvuZSFFY5T799zFHkI/4ab285cc61027551e8a97a597aade108/brady.png?w=109&h=50&q=50&fm=png&fit=scale 109w",
                      sizes:
                        "(min-width: 108.53658536585367px) 108.53658536585367px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 108.53658536585367,
                  height: 50,
                },
              },
              {
                id: "a9d20ff5-9052-5439-9430-6ba927174c0b",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/5oGXltv2uW9Ro4qLIDElc2/7432e8eb0d8d41f6517bca0f49efa8c9/arcx.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Wayne is a Senior Executive with more than twenty years of leadership experience in the building and industrial product sectors. During this time, he has gained extensive experience directing manufacturing, complex supply chains and end to end logistics organisations.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Wayne’s ethos is to challenge existing paradigms by applying systems thinking, technology and process automation solutions to achieve continuous organisational improvement, industry disruption and transformational change.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"As a passionate business professional, Wayne is driven by the goal of achieving world class innovation and driving change to deliver exceptional operational, customer and shareholder outcomes.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
          },
          {
            id: "c5639649-ba2f-532d-aac6-2bba56e9417d",
            name: "Paul Kouppas​",
            position: "Director –  Product Innovation​",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7ibj5hWL25qxinrGWxHgu1/f4a786c92dc0b17b3d1b3969b274c133/paul.png?w=390&h=390&q=100&fm=webp&bg=white 390w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/7ibj5hWL25qxinrGWxHgu1/f4a786c92dc0b17b3d1b3969b274c133/paul.png?w=390&h=390&q=100&fm=png&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/7ibj5hWL25qxinrGWxHgu1/f4a786c92dc0b17b3d1b3969b274c133/paul.png?w=390&h=390&q=100&fm=png&bg=white 390w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "7f4b1b4d-1a11-5472-bda3-deebf0d154da",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=37&h=13&q=50&fm=webp&fit=scale 37w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=74&h=25&q=50&fm=webp&fit=scale 74w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=148&h=50&q=50&fm=webp&fit=scale 148w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=296&h=100&q=50&fm=webp&fit=scale 296w",
                        sizes:
                          "(min-width: 147.58883248730965px) 147.58883248730965px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=148&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=37&h=13&q=50&fm=png&fit=scale 37w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=74&h=25&q=50&fm=png&fit=scale 74w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=148&h=50&q=50&fm=png&fit=scale 148w,\nhttps://images.ctfassets.net/thdo0maiis3p/1f0Xy9wBiCy0Uu6Gzo9phH/9818df3d7f28b3063bdfa9b618c91b3a/unity.png?w=296&h=100&q=50&fm=png&fit=scale 296w",
                      sizes:
                        "(min-width: 147.58883248730965px) 147.58883248730965px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 147.58883248730965,
                  height: 50,
                },
              },
              {
                id: "10c57cf5-3705-591c-ade6-f7e010a38ff3",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=40&h=13&q=50&fm=webp&fit=scale 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=80&h=25&q=50&fm=webp&fit=scale 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=159&h=50&q=50&fm=webp&fit=scale 159w",
                        sizes:
                          "(min-width: 159.45945945945945px) 159.45945945945945px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=159&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=40&h=13&q=50&fm=png&fit=scale 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=80&h=25&q=50&fm=png&fit=scale 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/3hvvAZWBfEIzf5SQMwTb9u/89392a02ac96d3dcf8b8e7c36fe021ed/moberg.png?w=159&h=50&q=50&fm=png&fit=scale 159w",
                      sizes:
                        "(min-width: 159.45945945945945px) 159.45945945945945px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 159.45945945945945,
                  height: 50,
                },
              },
              {
                id: "faaa2ba7-f2ab-5dd6-9c71-ea83374b4198",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/4CrIWfIh5g13lWlIJzdNDj/055dbbee219d7bea2b587028adf50d8c/a.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
              {
                id: "465fe2df-6f2d-5401-865d-f345f12bf900",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/7q4GafeQMLJ4vZkLBwdVnk/375f3fff4048d1ecfcb0b42df965c0a6/w.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
              {
                id: "260cc3f6-c5f1-5596-a84c-6c0a69598c8e",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/3JHlTMMMQFV5LSQpPBYubI/d0b4d11583c53ab92934d943a942349f/x.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Paul brings to PT Blink more than 20 years’ experience in successfully leading teams to build and launch cloud-based solutions including Augmented Reality (AR) and Virtual Reality (VR) applications and products spanning wearables, web, mobile and Internet of Things (IoT).","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"A hands-on leader, Paul ended up where he is “the hard way” by dedicating himself to many years of 3D modelling, animating, creating, designing, storytelling, developing concepts, prototyping, trying (and failing), learning, teaching and listening.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"A natural problem solver with a passion for helping people, Paul leads Product Innovation at PT Blink with a deep catalogue of achievements, including building more than 500 IOS and Android Mobile Apps; specialising in real-time 3D experiences for architecture, engineering and construction; and pioneering AR applications since 2007.","marks":[],"data":{}}]}]}',
            },
          },
          {
            id: "6ca9e344-57fe-547b-a322-9c7b15c9eb54",
            name: "Victor Martinez​",
            position: "Lead Software Developer/Computational Designer",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3EYWMVKvqMjj3KbK60AB0x/fa98d1b9d0d8ebfacbee9a887a5a0807/victor.png?w=722&h=722&q=100&fm=webp&bg=white 722w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/3EYWMVKvqMjj3KbK60AB0x/fa98d1b9d0d8ebfacbee9a887a5a0807/victor.png?w=722&h=722&q=100&fm=png&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/3EYWMVKvqMjj3KbK60AB0x/fa98d1b9d0d8ebfacbee9a887a5a0807/victor.png?w=722&h=722&q=100&fm=png&bg=white 722w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "34721fb4-3f4d-5fa0-a008-546db9484cee",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=27&h=13&q=50&fm=webp&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=54&h=25&q=50&fm=webp&fit=scale 54w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=108&h=50&q=50&fm=webp&fit=scale 108w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=216&h=100&q=50&fm=webp&fit=scale 216w",
                        sizes:
                          "(min-width: 107.84313725490196px) 107.84313725490196px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=108&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=27&h=13&q=50&fm=png&fit=scale 27w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=54&h=25&q=50&fm=png&fit=scale 54w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=108&h=50&q=50&fm=png&fit=scale 108w,\nhttps://images.ctfassets.net/thdo0maiis3p/5dNFHPzJEqzXanhx5O4bxP/bfa5a90cf2914dd772ff873010301a81/laing.png?w=216&h=100&q=50&fm=png&fit=scale 216w",
                      sizes:
                        "(min-width: 107.84313725490196px) 107.84313725490196px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 107.84313725490196,
                  height: 50,
                },
              },
              {
                id: "7bb75f7e-3344-56bf-9bbd-a896399db326",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=12&h=12&q=50&fm=webp&fit=scale 12w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=49&h=50&q=50&fm=webp&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=98&h=99&q=50&fm=webp&fit=scale 98w",
                        sizes:
                          "(min-width: 49.275362318840585px) 49.275362318840585px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=49&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=12&h=12&q=50&fm=png&fit=scale 12w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=49&h=50&q=50&fm=png&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/1m7HBUK4N03Ryrto1kLPcu/d2af678f5bd17791b45dbe51035df1fe/woods.png?w=98&h=99&q=50&fm=png&fit=scale 98w",
                      sizes:
                        "(min-width: 49.275362318840585px) 49.275362318840585px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 49.275362318840585,
                  height: 50,
                },
              },
              {
                id: "495502d5-4e74-54cc-b188-fea4432784aa",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=26&h=12&q=50&fm=webp&fit=scale 26w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=53&h=25&q=50&fm=webp&fit=scale 53w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=105&h=50&q=50&fm=webp&fit=scale 105w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=210&h=100&q=50&fm=webp&fit=scale 210w",
                        sizes:
                          "(min-width: 104.51612903225806px) 104.51612903225806px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=105&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=26&h=12&q=50&fm=png&fit=scale 26w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=53&h=25&q=50&fm=png&fit=scale 53w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=105&h=50&q=50&fm=png&fit=scale 105w,\nhttps://images.ctfassets.net/thdo0maiis3p/3o7v6dtnWPXIO5sAm6nj7g/d9d91e72d00ee32f3f86c6d0f9b6f9ec/monkey.png?w=210&h=100&q=50&fm=png&fit=scale 210w",
                      sizes:
                        "(min-width: 104.51612903225806px) 104.51612903225806px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 104.51612903225806,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Victor is software lead on the development of the Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" platform and responsible for computational design processes. ​","marks":[],"data":{}}]}]}',
            },
          },
          {
            id: "ce5992b4-6c57-5cac-94c2-4c0caed13a8a",
            name: "Andrew Giles",
            position: "Director Marketing & Comms",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/3UpPdzmUR4qhaaD9s4XGgx/640d6feeecd27946a79f853d7fdb04d5/Andrew-Giles.jpg?w=500&h=500&q=100&fm=webp&bg=white 500w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/3UpPdzmUR4qhaaD9s4XGgx/640d6feeecd27946a79f853d7fdb04d5/Andrew-Giles.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/3UpPdzmUR4qhaaD9s4XGgx/640d6feeecd27946a79f853d7fdb04d5/Andrew-Giles.jpg?w=500&h=500&fl=progressive&q=100&fm=jpg&bg=white 500w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "70c2e1df-1246-5092-9a38-8ee0784163fd",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=44&h=13&q=50&fm=webp&fit=scale 44w,\nhttps://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=87&h=25&q=50&fm=webp&fit=scale 87w,\nhttps://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=174&h=50&q=50&fm=webp&fit=scale 174w",
                        sizes:
                          "(min-width: 174.41860465116278px) 174.41860465116278px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=174&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=44&h=13&q=50&fm=png&fit=scale 44w,\nhttps://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=87&h=25&q=50&fm=png&fit=scale 87w,\nhttps://images.ctfassets.net/thdo0maiis3p/BNb7ft0dYdoNHI9SLqjYB/bff582110919d858c8756cd2a502333e/canon.png?w=174&h=50&q=50&fm=png&fit=scale 174w",
                      sizes:
                        "(min-width: 174.41860465116278px) 174.41860465116278px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 174.41860465116278,
                  height: 50,
                },
              },
              {
                id: "039c7bb4-eb80-5f31-aa4e-e38f12c29a99",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=63&h=13&q=50&fm=webp&fit=scale 63w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=126&h=25&q=50&fm=webp&fit=scale 126w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=251&h=50&q=50&fm=webp&fit=scale 251w",
                        sizes:
                          "(min-width: 251.21951219512195px) 251.21951219512195px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=251&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=63&h=13&q=50&fm=png&fit=scale 63w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=126&h=25&q=50&fm=png&fit=scale 126w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YjBiFMxqmCnOaurFjpBfa/11b1b2f1c0b6ddf5c3cc48cf85784139/sompo.png?w=251&h=50&q=50&fm=png&fit=scale 251w",
                      sizes:
                        "(min-width: 251.21951219512195px) 251.21951219512195px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 251.21951219512195,
                  height: 50,
                },
              },
              {
                id: "1cbca27a-73fc-508c-a2a7-ea86a6e7feeb",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=29&h=12&q=50&fm=webp&fit=scale 29w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=59&h=25&q=50&fm=webp&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=117&h=50&q=50&fm=webp&fit=scale 117w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=234&h=100&q=50&fm=webp&fit=scale 234w",
                        sizes:
                          "(min-width: 117.00680272108843px) 117.00680272108843px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=117&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=29&h=12&q=50&fm=png&fit=scale 29w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=59&h=25&q=50&fm=png&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=117&h=50&q=50&fm=png&fit=scale 117w,\nhttps://images.ctfassets.net/thdo0maiis3p/7f3j6FXGf1IQA3k1TaxCVv/fcde7348ac1fa6c319601d0bb9431306/edelman.png?w=234&h=100&q=50&fm=png&fit=scale 234w",
                      sizes:
                        "(min-width: 117.00680272108843px) 117.00680272108843px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 117.00680272108843,
                  height: 50,
                },
              },
              {
                id: "a2a96097-e238-5a9a-a693-a78aaf8cdcbc",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=60&h=12&q=50&fm=webp&fit=scale 60w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=121&h=25&q=50&fm=webp&fit=scale 121w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=241&h=50&q=50&fm=webp&fit=scale 241w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=482&h=100&q=50&fm=webp&fit=scale 482w",
                        sizes:
                          "(min-width: 240.68627450980392px) 240.68627450980392px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=241&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=60&h=12&q=50&fm=png&fit=scale 60w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=121&h=25&q=50&fm=png&fit=scale 121w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=241&h=50&q=50&fm=png&fit=scale 241w,\nhttps://images.ctfassets.net/thdo0maiis3p/7uRI3sG6xqoKcYcowxFgfn/f2d6a0d5ea5a7f4c9aea9270bcf48762/jwt.png?w=482&h=100&q=50&fm=png&fit=scale 482w",
                      sizes:
                        "(min-width: 240.68627450980392px) 240.68627450980392px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 240.68627450980392,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Andrew is a senior marketing and communications professional and business leader with more than 20 years’ experience running corporate communications, public relations and integrated marketing functions in Australia and Japan.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"At PT Blink, Andrew steers our brand development and market engagement using his broad experience advising businesses and introducing industry-changing products and services. These have ranged from consumer and enterprise technology hardware and services to customer experiences, eCommerce platforms and content for inspiration and education.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Andrew is driven by a passion for creating authentic and meaningful connections with all stakeholders and advancing industry change through innovation for a more productive, effective and sustainable world.","marks":[],"data":{}}]}]}',
            },
          },
          {
            id: "47afefd9-7ce5-512e-9f76-cf545d0ece3b",
            name: "Richard Sharps",
            position: "Chief Financial Officer",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=750&h=750&q=100&fm=webp&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1080&h=1080&q=100&fm=webp&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1366&h=1366&q=100&fm=webp&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1428&h=1428&q=100&fm=webp&bg=white 1428w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1428&h=1428&q=100&fm=png&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=750&h=750&q=100&fm=png&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1080&h=1080&q=100&fm=png&bg=white 1080w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1366&h=1366&q=100&fm=png&bg=white 1366w,\nhttps://images.ctfassets.net/thdo0maiis3p/1Mhv45tQBRceYyGGxBP9fy/22324113f3e7f488485cee3d8da5aa90/Richard_Sharps_2.png?w=1428&h=1428&q=100&fm=png&bg=white 1428w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "d79f4868-dbb0-5eb7-9924-079cd1e95ef8",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=25&h=13&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=49&h=25&q=50&fm=webp&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=webp&fit=scale 98w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=196&h=100&q=50&fm=webp&fit=scale 196w",
                        sizes:
                          "(min-width: 98.07692307692307px) 98.07692307692307px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=25&h=13&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=49&h=25&q=50&fm=png&fit=scale 49w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=98&h=50&q=50&fm=png&fit=scale 98w,\nhttps://images.ctfassets.net/thdo0maiis3p/4xQyRqC5mAm0lRRFWdHXAT/00f373b00f5d6583f4d8cde9d0410fd0/alspec.png?w=196&h=100&q=50&fm=png&fit=scale 196w",
                      sizes:
                        "(min-width: 98.07692307692307px) 98.07692307692307px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 98.07692307692307,
                  height: 50,
                },
              },
              {
                id: "22fcc0eb-6e33-5fa6-800a-38a0b124d5ee",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=15&h=13&q=50&fm=webp&fit=scale 15w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=30&h=26&q=50&fm=webp&fit=scale 30w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=59&h=50&q=50&fm=webp&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=118&h=101&q=50&fm=webp&fit=scale 118w",
                        sizes:
                          "(min-width: 58.65384615384615px) 58.65384615384615px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=59&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=15&h=13&q=50&fm=png&fit=scale 15w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=30&h=26&q=50&fm=png&fit=scale 30w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=59&h=50&q=50&fm=png&fit=scale 59w,\nhttps://images.ctfassets.net/thdo0maiis3p/757w9Mr02msnUBucyfj46b/37a9eba346eced2a2b7e13f723094f06/logo.png?w=118&h=101&q=50&fm=png&fit=scale 118w",
                      sizes:
                        "(min-width: 58.65384615384615px) 58.65384615384615px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 58.65384615384615,
                  height: 50,
                },
              },
              {
                id: "5db44500-9910-5143-895c-22a1644e4338",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=31&h=13&q=50&fm=webp&fit=scale 31w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=62&h=25&q=50&fm=webp&fit=scale 62w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=124&h=50&q=50&fm=webp&fit=scale 124w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=248&h=100&q=50&fm=webp&fit=scale 248w",
                        sizes:
                          "(min-width: 123.95833333333333px) 123.95833333333333px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=124&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=31&h=13&q=50&fm=png&fit=scale 31w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=62&h=25&q=50&fm=png&fit=scale 62w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=124&h=50&q=50&fm=png&fit=scale 124w,\nhttps://images.ctfassets.net/thdo0maiis3p/4Zxv4Xrot6e6zFxXAvcHxZ/f2262718381bf4022e93020b78134515/fletcher.png?w=248&h=100&q=50&fm=png&fit=scale 248w",
                      sizes:
                        "(min-width: 123.95833333333333px) 123.95833333333333px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 123.95833333333333,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Richard is a Fellow of the Association of Chartered Certified Accountants and has over 30 years’ finance and business experience. Richard started his career in the UK as an external auditor before relocating to Australia in 1998.  In Australia, his career initially continued within the external audit field before moving into business risk and commercial finance roles.   ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Richard has held Chief Financial Officer and Company Secretary roles in both the building materials industry and not for profit sector. Prior to joining PT Blink, he was the Director of Finance & Governance and Company Secretary at the Black Dog Institute. In his current role, Richard oversees the financial strategy, legal, HR and governance of PT Blink.   ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Richard is passionate about delivering strong, mentally healthy and sustainable teams and financial environments to promote innovative thinking and allow both the people and business to flourish. ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
          },
          {
            id: "b76c9868-485b-58a2-acfa-d0ec8d684eaa",
            name: "Rameshwar Prasad​",
            position: "Snr Structural & Technology Engineer​",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/5BuhAEqFm7qkqM2QPXFkS2/6d308fd0b3e8ac211086026ca2cdad42/rameshwar.png?w=548&h=548&q=100&fm=webp&bg=white 548w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/5BuhAEqFm7qkqM2QPXFkS2/6d308fd0b3e8ac211086026ca2cdad42/rameshwar.png?w=548&h=548&q=100&fm=png&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/5BuhAEqFm7qkqM2QPXFkS2/6d308fd0b3e8ac211086026ca2cdad42/rameshwar.png?w=548&h=548&q=100&fm=png&bg=white 548w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "f1ec7c9e-ece4-52ad-bba7-f4491a31ce80",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=21&h=13&q=50&fm=webp&fit=scale 21w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=42&h=25&q=50&fm=webp&fit=scale 42w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=83&h=50&q=50&fm=webp&fit=scale 83w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=166&h=100&q=50&fm=webp&fit=scale 166w",
                        sizes:
                          "(min-width: 83.08823529411765px) 83.08823529411765px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=83&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=21&h=13&q=50&fm=png&fit=scale 21w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=42&h=25&q=50&fm=png&fit=scale 42w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=83&h=50&q=50&fm=png&fit=scale 83w,\nhttps://images.ctfassets.net/thdo0maiis3p/2Q4SncQ2iICHEYpTuKq40J/3ee6c1ba1c5c7b986018591686454bf4/jacobs.png?w=166&h=100&q=50&fm=png&fit=scale 166w",
                      sizes:
                        "(min-width: 83.08823529411765px) 83.08823529411765px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 83.08823529411765,
                  height: 50,
                },
              },
              {
                id: "391ad2ea-852c-596c-8ebf-96e84f6d107d",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=21&h=12&q=50&fm=webp&fit=scale 21w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=42&h=25&q=50&fm=webp&fit=scale 42w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=84&h=50&q=50&fm=webp&fit=scale 84w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=168&h=100&q=50&fm=webp&fit=scale 168w",
                        sizes:
                          "(min-width: 84.10404624277457px) 84.10404624277457px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=84&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=21&h=12&q=50&fm=png&fit=scale 21w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=42&h=25&q=50&fm=png&fit=scale 42w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=84&h=50&q=50&fm=png&fit=scale 84w,\nhttps://images.ctfassets.net/thdo0maiis3p/7t1MBKuuV7BPGjzOols6Ed/bce28994484a93cc32577379229f567e/tata.png?w=168&h=100&q=50&fm=png&fit=scale 168w",
                      sizes:
                        "(min-width: 84.10404624277457px) 84.10404624277457px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 84.10404624277457,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Rameshwar leads structural engineer solutions through computational systems and drive task automation, parametric solutions and API interoperability.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
          },
          {
            id: "78843b97-072c-52d7-8c7b-3fb78fc1a7ee",
            name: "Anirudha Agara​",
            position: "Computational Designer​",
            image: {
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2ao0raU1CtjRw79AJ935IS/22fdb076e7b47079f57308aaeb2af7fe/anirudha.png?w=539&h=539&q=100&fm=webp&bg=white 539w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2ao0raU1CtjRw79AJ935IS/22fdb076e7b47079f57308aaeb2af7fe/anirudha.png?w=539&h=539&q=100&fm=png&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2ao0raU1CtjRw79AJ935IS/22fdb076e7b47079f57308aaeb2af7fe/anirudha.png?w=539&h=539&q=100&fm=png&bg=white 539w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 1,
              },
            },
            logos: [
              {
                id: "755ba710-036d-5755-882c-79f1f9d31784",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/6YXKEy1jMr4NHe7QXYeptZ/8b07a40f644b14d2b2c8c90a30419c29/jds.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
              {
                id: "88d7c0ee-4051-56a6-9e84-c7024b957afa",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/tgEInBQODlv2H4BYh7YWT/983dd5364a1eefe3545ef826a41ec97d/arpm.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
              {
                id: "f3d98728-e332-55d6-819c-f4aadbfe48e1",
                gatsbyImageData: {
                  images: {
                    sources: [
                      {
                        srcSet:
                          "https://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=13&h=13&q=50&fm=webp&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=25&h=25&q=50&fm=webp&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=50&h=50&q=50&fm=webp&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=100&h=100&q=50&fm=webp&fit=scale 100w",
                        sizes: "(min-width: 50px) 50px, 100vw",
                        type: "image/webp",
                      },
                    ],
                    fallback: {
                      src: "https://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=50&h=50&q=50&fm=png&fit=scale",
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=13&h=13&q=50&fm=png&fit=scale 13w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=25&h=25&q=50&fm=png&fit=scale 25w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=50&h=50&q=50&fm=png&fit=scale 50w,\nhttps://images.ctfassets.net/thdo0maiis3p/1IN2KqIk9dpHCSuT4kQcrx/5df0148b489819787a037368e22b972b/ballast.png?w=100&h=100&q=50&fm=png&fit=scale 100w",
                      sizes: "(min-width: 50px) 50px, 100vw",
                    },
                  },
                  layout: "constrained",
                  width: 50,
                  height: 50,
                },
              },
            ],
            bio: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Ani develops computational design solutions and is engaged in the critical inter-relationship between architecture, computation and visual media presentation.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
          },
        ],
        id: "21933f5e-073d-5d38-9a78-e0dea7693d04",
      },
      {
        __typename: "ContentfulTestimonialsSection",
        heading: "Industry Membership",
        background: "white",
        testimonials: [
          {
            id: "83461848-be1c-5e07-ab09-4f887d63a84c",
            image: {
              title: "CIFE",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=35&h=20&q=100&fm=webp 35w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=69&h=40&q=100&fm=webp 69w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=138&h=80&q=100&fm=webp 138w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=276&h=160&q=100&fm=webp 276w",
                      sizes:
                        "(min-width: 137.57523645743765px) 137.57523645743765px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=138&h=80&fl=progressive&q=100&fm=jpg",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=35&h=20&fl=progressive&q=100&fm=jpg 35w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=69&h=40&fl=progressive&q=100&fm=jpg 69w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=138&h=80&fl=progressive&q=100&fm=jpg 138w,\nhttps://images.ctfassets.net/thdo0maiis3p/1GDANO6ZgUDbTmiRz1obRg/a88bd05b22631102b424504a5557c9ae/cife_logo_14th_june_0.jpg?w=276&h=160&fl=progressive&q=100&fm=jpg 276w",
                    sizes:
                      "(min-width: 137.57523645743765px) 137.57523645743765px, 100vw",
                  },
                },
                layout: "constrained",
                width: 137.57523645743765,
                height: 80,
              },
            },
            summary:
              "A community of researchers and industry members who together shape the future of the Architecture, Engineering, Construction, and Operation industry. ",
            name: "Stanford University - Center for Integrated Facility Engineering (CIFE)",
            companyName: null,
            link: "https://cife.stanford.edu/",
          },
          {
            id: "705a507b-c480-5ac2-98ce-81a750e17eb2",
            image: {
              title: "Australia Japan Business Co-operation Committee",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=43&h=20&q=100&fm=webp 43w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=85&h=40&q=100&fm=webp 85w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=170&h=80&q=100&fm=webp 170w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=340&h=160&q=100&fm=webp 340w",
                      sizes:
                        "(min-width: 169.65517241379308px) 169.65517241379308px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=170&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=43&h=20&q=100&fm=png 43w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=85&h=40&q=100&fm=png 85w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=170&h=80&q=100&fm=png 170w,\nhttps://images.ctfassets.net/thdo0maiis3p/2OwNmakl7fZ1PPKIc9jFGT/0829ae36cba251f375a833638f514baf/AJBCC_Logo.png?w=340&h=160&q=100&fm=png 340w",
                    sizes:
                      "(min-width: 169.65517241379308px) 169.65517241379308px, 100vw",
                  },
                },
                layout: "constrained",
                width: 169.65517241379308,
                height: 80,
              },
            },
            summary:
              "The AJBCC’s objective is to promote and increase business opportunities and strengthen ties between Australia and Japan.",
            name: "Australia Japan Business Co-operation Committee",
            companyName: null,
            link: "https://ajbcc.com.au/",
          },
          {
            id: "5263f213-8438-598f-ad06-1845d8e31c7e",
            image: {
              title: "AMGC",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=62&h=20&q=100&fm=webp 62w,\nhttps://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=124&h=40&q=100&fm=webp 124w,\nhttps://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=247&h=80&q=100&fm=webp 247w",
                      sizes: "(min-width: 247px) 247px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=247&h=80&fl=progressive&q=100&fm=jpg",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=62&h=20&fl=progressive&q=100&fm=jpg 62w,\nhttps://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=124&h=40&fl=progressive&q=100&fm=jpg 124w,\nhttps://images.ctfassets.net/thdo0maiis3p/2zTuG89qdkLd1erABYLjwh/eb9a6188e94ba78d44a0a1ef368af72d/AMGC_Master_Logo_RGB_dark_400_1.jpg?w=247&h=80&fl=progressive&q=100&fm=jpg 247w",
                    sizes: "(min-width: 247px) 247px, 100vw",
                  },
                },
                layout: "constrained",
                width: 247,
                height: 80,
              },
            },
            summary:
              "The Advanced Manufacturing Growth Centre’s (AMGC) goal is to drive innovation, productivity and competitiveness across Australia’s manufacturing industry.",
            name: "Advanced Manufacturing Growth Centre",
            companyName: null,
            link: "https://www.amgc.org.au/",
          },
          {
            id: "fd1a7732-c405-5ab1-a07e-f185952daf42",
            image: {
              title: "prefabAUS",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=23&h=20&q=100&fm=webp 23w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=46&h=40&q=100&fm=webp 46w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=92&h=80&q=100&fm=webp 92w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=184&h=160&q=100&fm=webp 184w",
                      sizes:
                        "(min-width: 91.96141479099678px) 91.96141479099678px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=92&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=23&h=20&q=100&fm=png 23w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=46&h=40&q=100&fm=png 46w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=92&h=80&q=100&fm=png 92w,\nhttps://images.ctfassets.net/thdo0maiis3p/16KQnyxWlRqJbVZ6NXo7nN/07fb79a93515b457393d10dd9b939fed/prefabAUS.png?w=184&h=160&q=100&fm=png 184w",
                    sizes:
                      "(min-width: 91.96141479099678px) 91.96141479099678px, 100vw",
                  },
                },
                layout: "constrained",
                width: 91.96141479099678,
                height: 80,
              },
            },
            summary:
              "prefabAUS is the peak body for Australia’s off-site construction industry and acts as the hub for building prefabrication technology and design.",
            name: "prefabAUS",
            companyName: null,
            link: "https://www.prefabaus.org.au/",
          },
          {
            id: "4a58eefa-0f55-5903-ac57-39489110911d",
            image: {
              title: "C-Tech Club Members Badge",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=20&h=20&q=100&fm=webp 20w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=40&h=40&q=100&fm=webp 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=80&h=80&q=100&fm=webp 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=160&h=160&q=100&fm=webp 160w",
                      sizes: "(min-width: 80px) 80px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=80&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=20&h=20&q=100&fm=png 20w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=40&h=40&q=100&fm=png 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=80&h=80&q=100&fm=png 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/1r5sXQmWLd3ccwSgpGkx8T/acd9ac8a40aa776141ea24dd4d728760/C-Tech_Club_Members_Badge.png?w=160&h=160&q=100&fm=png 160w",
                    sizes: "(min-width: 80px) 80px, 100vw",
                  },
                },
                layout: "constrained",
                width: 80,
                height: 80,
              },
            },
            summary:
              "The C-Tech Club is a global, not-for-profit group comprising those working independently to lead the technology revolution in construction.",
            name: "C-Tech Club",
            companyName: null,
            link: "https://www.c-techclub.org/",
          },
          {
            id: "1f43ac90-a358-5857-8cd7-c00c44eebd51",
            image: {
              title: "Suppliers Declare logo",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=19&h=21&q=100&fm=webp 19w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=37&h=40&q=100&fm=webp 37w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=74&h=81&q=100&fm=webp 74w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=148&h=161&q=100&fm=webp 148w",
                      sizes:
                        "(min-width: 73.53919239904988px) 73.53919239904988px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=74&h=81&fl=progressive&q=100&fm=jpg",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=19&h=21&fl=progressive&q=100&fm=jpg 19w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=37&h=40&fl=progressive&q=100&fm=jpg 37w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=74&h=81&fl=progressive&q=100&fm=jpg 74w,\nhttps://images.ctfassets.net/thdo0maiis3p/VBUtLzJO90IGn5i96wEhS/2f08006ea6bef1fb9f7f1cf68b553b89/banner2_Suppliers_Declare.jpg?w=148&h=161&fl=progressive&q=100&fm=jpg 148w",
                    sizes:
                      "(min-width: 73.53919239904988px) 73.53919239904988px, 100vw",
                  },
                },
                layout: "constrained",
                width: 73.53919239904988,
                height: 80,
              },
            },
            summary:
              "A global sustainability movement created to bring greater attention to the need for sustainable practices in supplying and manufacturing industries.",
            name: "Suppliers Declare",
            companyName: null,
            link: "https://www.suppliersdeclare.com/",
          },
          {
            id: "55ea335a-69a9-54a6-8c90-815647187140",
            image: {
              title: "PropTech Association of Australia",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=40&h=20&q=100&fm=webp 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=80&h=40&q=100&fm=webp 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=160&h=80&q=100&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=320&h=160&q=100&fm=webp 320w",
                      sizes: "(min-width: 160px) 160px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=160&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=40&h=20&q=100&fm=png 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=80&h=40&q=100&fm=png 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=160&h=80&q=100&fm=png 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/27AVHbD7YL2eNKejeg3rCm/44cf30a0938aac90e75847df2c5790d9/Proptech_Association_of_Aust.png?w=320&h=160&q=100&fm=png 320w",
                    sizes: "(min-width: 160px) 160px, 100vw",
                  },
                },
                layout: "constrained",
                width: 160,
                height: 80,
              },
            },
            summary:
              "We grow the property technology marketplace by helping the property industry feel more confident about adopting and investing in innovation",
            name: "PropTech Association of Australia",
            companyName: null,
            link: "https://proptechassociation.com.au/",
          },
          {
            id: "fea1f2d2-621c-5147-9194-ba4e0eda7216",
            image: {
              title: "MECLA",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=46&h=20&q=100&fm=webp 46w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=92&h=40&q=100&fm=webp 92w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=184&h=80&q=100&fm=webp 184w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=368&h=160&q=100&fm=webp 368w",
                      sizes:
                        "(min-width: 183.67713004484304px) 183.67713004484304px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=184&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=46&h=20&q=100&fm=png 46w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=92&h=40&q=100&fm=png 92w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=184&h=80&q=100&fm=png 184w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qMmbvgXTRHhBUtjlvfV0r/24f955296601b7c174483bd8ed5e6653/MECLA_LOGO_Positive-1024x446.png?w=368&h=160&q=100&fm=png 368w",
                    sizes:
                      "(min-width: 183.67713004484304px) 183.67713004484304px, 100vw",
                  },
                },
                layout: "constrained",
                width: 183.67713004484304,
                height: 80,
              },
            },
            summary:
              "Materials & Embodied Carbon Leaders’ Alliance (MECLA) brings together the drive to reduce embodied carbon in the building and construction industry.",
            name: "MECLA",
            companyName: null,
            link: "https://mecla.org.au/",
          },
          {
            id: "521b4fcd-4458-5f8c-ad8b-f85680528a2a",
            image: {
              title: "ARAD",
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=40&h=20&q=100&fm=webp 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=80&h=40&q=100&fm=webp 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=160&h=80&q=100&fm=webp 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=320&h=160&q=100&fm=webp 320w",
                      sizes:
                        "(min-width: 159.64523281596453px) 159.64523281596453px, 100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=160&h=80&q=100&fm=png",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=40&h=20&q=100&fm=png 40w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=80&h=40&q=100&fm=png 80w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=160&h=80&q=100&fm=png 160w,\nhttps://images.ctfassets.net/thdo0maiis3p/62wqsIz4zVovCESnyxaRND/147c07a132163d7c8fcc0c805c000eee/ARAD.png?w=320&h=160&q=100&fm=png 320w",
                    sizes:
                      "(min-width: 159.64523281596453px) 159.64523281596453px, 100vw",
                  },
                },
                layout: "constrained",
                width: 159.64523281596453,
                height: 80,
              },
            },
            summary:
              "ARAD is a prominent industry association dedicated to the advancement of rooftop and airspace development in Australia, New Zealand, the UK, NYC, and Canada.",
            name: "Association of Rooftop and Airspace Development",
            companyName: "Association of Rooftop and Airspace Development",
            link: "www.arad.au",
          },
        ],
        id: "14c60bc3-6de8-539a-925b-5044d5012829",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Investors, Media & Careers",
        displayType: "light-blue",
        numberOfColumns: 3,
        headingLogo: null,
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "e41e94a1-9b7d-5168-96ef-44448849e441",
            title: {
              internal: {
                content: "Investor Centre",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"View investor events, presentations, financials and corporate governance​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/investor",
            ctaText: "View announcements",
          },
          {
            id: "e5ae4eaf-719a-53d9-b0fd-b33c5f8f4b77",
            title: {
              internal: {
                content: "Press room",
              },
            },
            image: null,
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"View all of PT Blink\'s latest news releases and media materials​","marks":[],"data":{}}],"data":{}}]}',
            },
            ctaLink: "/press-room/",
            ctaText: "View press room",
          },
          {
            id: "0120c4cc-ca2f-538c-b87a-65e9dc144499",
            title: {
              internal: {
                content: "Careers",
              },
            },
            image: null,
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Help us change the way property is developed and operated.","marks":[],"data":{}}],"data":{}}]}',
            },
            ctaLink: "/careers/",
            ctaText: "View Careers",
          },
        ],
        id: "807614cc-cf8e-57f2-bc8e-6bde5dd9dc94",
      },
      {
        __typename: "ContentfulCtaSection",
        heading: "Contact Us",
        subHeading: "Have any questions? Let us know and we will be in touch.",
        ctaLink: "/contact/",
        ctaText: "Contact Us",
        id: "13e6e125-9a3d-5d5e-8a20-827085fc18e6",
      },
    ],
  },
};

function About() {
  return <Page data={data} />;
}

export default About;
